import React from 'react'
import Layout from '../components/layout'

class ZuMeinerPerson extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout seoTitle="Zu meiner Person | Praxis für Psychotherapie Telgte">
        <div id="main">
          <section id="intro" className="main">
            <div>
              <header className="major">
                <h2>Zu meiner Person</h2>
              </header>
              <div>
                <h1>Angelika Behrenberg</h1>
                <p>
                  geboren 1963 in Bottrop verheiratet, 2 Söhne ich lebe und
                  arbeite in Telgte
                </p>
                <p>
                  Studium der Psychologie und Kath. Theologie in Münster 1982 –
                  1988 beide Studiengänge abgeschlossen mit dem Diplom
                </p>
                <p>
                  Ausbildungen in den Feldern Psychotherapie, Beratung und
                  Fortbildung
                </p>
                <ul>
                  <li>
                    als Gesprächspsychotherapeutin und Verhaltenstherapeutin
                  </li>
                  <li>als Trainerin für Gruppendynamik DAGG und DGGO</li>
                  <li>als Supervisorin BDP und DGSv</li>
                </ul>
                <p>
                  Beruflicher Werdegang Langjährige hauptamtlich angestellte
                  Tätigkeiten: 1989 – 1993 als Referentin für Psychologische und
                  Soziale Bildungsarbeit 1993 – 2000 als Beraterin und
                  Psychotherapeutin in einer Beratungseinrichtung 1993 – 2000
                  zusätzlich nebenberufliche Tätigkeit in einer
                  psychotherapeutischen Gemeinschaftspraxis und als
                  freiberufliche Referentin
                </p>
                <p>
                  Praxisgründung Im Februar 2000 Mitbegründerin einer
                  Praxengemeinschaft in Telgte gemeinsam mit Dipl.-Psych. Rita
                  Averbeck und Dipl.-Psych. Michael Faßnacht
                </p>
                <p>
                  2000 – heute Tätigkeit in eigener Praxis als Psychologische
                  Psychotherapeutin, Beraterin, Trainerin, Supervisorin und
                  Coach
                </p>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default ZuMeinerPerson
